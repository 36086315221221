<!--  -->
<template>
  <transition :css="false" @enter="enterHeader" @leave="leaveHeader">
    <div class="header" ref="header">
      <div class="top">
        <div class="topW">
          <div class="topW_subLeft" @click="goIndex(1)">
            <img
              class="topW_subLeft_logo"
              src="../assets/images/logo.png"
              alt=""
            />
            <span class="topW_subLeft_title">官渡区楼宇经济信息平台</span>
          </div>
          <!-- <div class="topW_subRight" @click="userLogin">
                {{!getIsLogin?'登录':'退出'}}
            </div> -->
        </div>
      </div>

      <div class="middle" ref="middle">
        <img class="middle_title" src="../assets/images/homeTitle.png" alt="" />
        <div class="middle_search">
          <i
            v-show="this.keyword != ''"
            class="el-icon-circle-close middle_search_close"
            @click="clearKeyWord"
          ></i>
          <input
            class="middle_search_input"
            autocomplete="“off”"
            @keyup="search_input"
            placeholder="请输入项目名称"
            type="text"
            v-model="keyword"
            @blur="inputBlur"
            @focus="inputFocus"
          />
          <div class="middle_search_line"></div>
          <div class="mapBtn" @click="goSearch(3)">
            <img
              class="mapBtn_icon"
              src="../assets/images/mapIcon.png"
              alt=""
            />
            <div class="mapBtn_txt">地图</div>
          </div>
          <div class="searchBtn" @click="goSearch(2)">
            <img
              class="searchBtn_icon"
              src="../assets/images/searchIcon.png"
              alt=""
            />
            <div class="searchBtn_txt">搜索</div>
          </div>

          <transition :css="false" @enter="enter" @leave="leave">
            <div class="keywordCont" ref="keywordCont">
              <div class="keywordCont_title">热门搜索</div>
              <div class="keywordCont_cont">
                <div
                  class="keywordCont_cont_sub"
                  v-for="(item, index) in keywordArr"
                  :key="index"
                  @click="addKeyWord(item)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <transition :css="false" @enter="enterBotom" @leave="leaveBotom">
        <div class="bottom" ref="bottomCont">
          <div class="bottom_info1 active" ref="btn1" @click="changePage(1)">
            <div class="sub">
              <div class="bottom_img"></div>
              <div class="bottom_txt">首页资讯</div>
            </div>
          </div>

          <div class="bottom_info2" ref="btn2" @click="changePage(2)">
            <div class="sub">
              <div class="bottom_img"></div>
              <div class="bottom_txt">楼宇查询</div>
            </div>
          </div>

          <div class="bottom_info3" ref="btn3" @click="changePage(3)">
            <div class="sub">
              <div class="bottom_img"></div>
              <div class="bottom_txt">楼宇地图</div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import Velocity from "velocity-animate";
import "velocity-animate/velocity.ui";
import { mapActions, mapGetters } from "vuex";
import { userLogin, isLogin } from "@/api/index";
import mode from "@/api/modeType";

export default {
  name: "Tabber",
  data() {
    return {
      scaleHeight: 0,
      preId: 1,
      keyword: "",
      keywordArr: [
        "自贸数字港",
        "滇池国际会展中心",
        "银海金岸广场",
        "鑫都韵城",
        "凯旋利商业广场",
        "螺蛳湾",
        "融城金阶",
        "海伦国际",
        "鼎盛大厦",
        "双城际商务中心",
        "世纪金源",
        "高原明珠商务中心",
        "城投大厦",
        "芳华里",
      ],
    };
  },

  components: {},

  props: {},

  computed: {
    ...mapGetters(["getPageID", "getIsLogin", "getKeyword"]),
  },

  watch: {
    getPageID(newValue, oldValue) {
      // console.log(newValue,'newValue111')
      // console.log(oldValue,'oldValue111')
      // console.log(this.preId,'this.preId11')

      //  if(newValue<4){
      //     this.$refs["btn"+newValue].classList.add('active')
      //     console.log('ddd1111')
      //    if((this.preId>1 && this.preId<4) || oldValue<4)
      //     this.$refs["btn"+this.preId].classList.remove('active')
      //     console.log('ddd22222')
      //     // this.preId=newValue
      //     console.log(this.preId,'this.preId222')

      //  }

      if (newValue < 4) this.$refs["btn" + newValue].classList.add("active");
      if (this.preId < 4)
        this.$refs["btn" + this.preId].classList.remove("active");
      // this.preId=newValue
      // console.log(this.preId,'this.preId222')

      if (newValue == 1) {
        this.keyword = "";
        this.setKeyword("");
      }
      if (newValue != 3 && newValue != 4 && newValue != 5) {
        this.enterHeader();
      } else {
        if (newValue == 4) this.scaleHeight = 64;
        if (newValue == 5) {
          this.$refs.middle.style.marginTop = "188px";
          this.scaleHeight = 439;
          var top =
            document.body.scrollTop || document.documentElement.scrollTop;
          scrollBy(0, -top);
        }
        if (newValue == 3) {
          this.scaleHeight = 64;
          var top =
            document.body.scrollTop || document.documentElement.scrollTop;
          scrollBy(0, -top);
        }

        this.leaveHeader();
      }
      if ((newValue == 1 && oldValue == 4) || newValue == 4) {
        var top = document.body.scrollTop || document.documentElement.scrollTop;
        scrollBy(0, -top);
      }
      if (newValue < 3 && oldValue == 5) {
        this.$refs.middle.style.marginTop = "255px";
        var top = document.body.scrollTop || document.documentElement.scrollTop;
        scrollBy(0, -top);
      }
      this.preId = newValue;
    },
  },

  methods: {
    ...mapActions(["setPageID", "setKeyword", "setIsLogin", "setTopBoo"]),

    userLogin() {
      this.$emit("userLoginOpen");
    },
    clearKeyWord() {
      this.keyword = "";
      this.setKeyword("");
    },
    addKeyWord(_str) {
      this.keyword = _str;
    },
    enter(el, done) {
      Velocity(
        this.$refs.keywordCont,
        "transition.slideUpIn",
        { opacity: 1 },
        { duration: 10 },
        function () {
          done();
        }
      );
    },
    leave(el, done) {
      Velocity(
        this.$refs.keywordCont,
        "transition.slideDownOut",
        { opacity: 0 },
        { duration: 10 },
        function () {
          done();
        }
      );
    },

    enterBotom(el, done) {
      Velocity(
        this.$refs.bottomCont,
        { opacity: 1 },
        { duration: 300 },
        function () {
          done();
        }
      );
    },
    leaveBotom(el, done) {
      Velocity(
        this.$refs.bottomCont,
        { opacity: 0 },
        { duration: 300 },
        function () {
          done();
        }
      );
    },

    enterHeader(el, done) {
      Velocity(
        this.$refs.header,
        { height: 716 },
        { duration: 600 },
        function () {
          done();
        }
      );
    },
    leaveHeader(el, done) {
      Velocity(
        this.$refs.header,
        { height: this.scaleHeight },
        { duration: 600 },
        function () {
          done();
        }
      );
    },

    inputBlur() {
      // console.log(111)
      this.leave();
      this.enterBotom();
    },
    inputFocus() {
      // console.log(222)

      this.enter();
      this.leaveBotom();
    },

    changePage(_id) {
      if (_id == this.preId) return;
      let routerArr = ["/", "/roomQuery", "/roomMap"];
      //  console.log(this.preId)
      //  this.$refs["btn"+_id].classList.add('active')
      //  if(this.preId!=0)
      //   this.$refs["btn"+this.preId].classList.remove('active')
      //  this.preId=_id
      if (_id == 2 || _id == 3) {
        this.setKeyword("");
      }
      //  this.leaveHeader()
      this.setPageID(_id);

      this.$router.push({ path: routerArr[_id - 1] });
    },
    goSearch(_id) {
      //  console.log(this.keyword=='','this.keyword==')
      if ((this.keyword == "" || this.keyword.trim().length == 0) && _id != 3) {
        this.keyword = "";
        this.setKeyword("");
        return;
      }

      //  console.log(this.preId,'111')
      this.setTopBoo(true);
      if (this.getKeyword == this.keyword) {
        if (_id == this.preId) return;
      }

      //  console.log(this.preId,'22222')

      //  console.log(_id,'_id_id3333')

      let routerArr = ["/", "/roomQuery", "/roomMap"];
      mode.topBoo = true;

      if (this.getPageID == 2) {
        if (_id == 3) {
          this.setPageID(_id);

          this.$router.push({ path: routerArr[_id - 1] });
          // console.log(this.preId)
          this.$refs["btn" + _id].classList.add("active");
          if (this.preId != 2)
            this.$refs["btn" + this.preId].classList.remove("active");
        }
      } else {
        this.setPageID(_id);
        this.$router.push({ path: routerArr[_id - 1] });
        // console.log(this.preId)
        // this.setKeyword(this.keyword)
        this.$refs["btn" + _id].classList.add("active");
        // console.log(this.keyword,'3doaodoasodoasod')
        if (this.preId != 2 && this.$refs["btn" + this.preId])
          this.$refs["btn" + this.preId].classList.remove("active");
        // this.preId=_id
      }

      this.setKeyword(this.keyword);
    },
    search_input(e) {
      //  console.log(e.keyCode,'e.keyCode·')
      //  console.log(this.keyword.trim().length,'data.trim().length')
      if (e.keyCode == 13) {
        this.goSearch(2);
      }
      // if(e.keyCode==32){
      //   this.keyword=''
      // }
    },
    goIndex(_id) {
      if (this.getPageID == 1) return;

      let routerArr = ["/", "/roomQuery", "/roomMap"];
      this.setPageID(_id);
      this.$router.push({ path: routerArr[_id - 1] });
      //  console.log(this.preId)
      //  this.$refs["btn"+_id].classList.add('active')
      //  if(this.preId>1)
      //   this.$refs["btn"+this.preId].classList.remove('active')
      //  this.preId=_id

      //  this.leaveHeader()
    },
  },

  created() {}, //生命周期 - 创建完成（可以访问当前this实例）
  mounted() {
    // console.log("cococococococo")
    isLogin()
      .then((data) => {
        if (data.code == 200) {
          // console.log(data,'isLogin')
          this.setIsLogin(data.data);
        } else {
          this.$message.error(data.message);
        }
        // this.playlist = data.playlist
      })
      .catch((err) => {
        console.log(err);
        this.$message.error(err);
      });
  }, //生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss">
.header {
  [class*=" el-icon-"],
  [class^="el-icon-"] {
    color: white;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/variable";

.header {
  width: 100%;
  height: 716px;
  background: url("../assets/images/homeBg.jpg") no-repeat center;
  background-size: cover;
  position: relative;
  top: 0;

  .top {
    width: 100%;
    height: 64px;
    background: rgba(0, 59, 148, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    // text-align: left;
    font-size: $font_medium;
    color: white;
    z-index: 100;
    .topW {
      min-width: $page-width;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_subLeft {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 6px 0 6px 40px;
        &_logo {
          width: 52px;
          height: 52px;
        }
        &_title {
          margin-left: 15px;
        }
      }
      &_subRight {
        margin-right: 39px;
        width: 64px;
        height: 32px;
        box-sizing: border-box;
        border: 1px solid rgba($color: #ffffff, $alpha: 0.8);
        text-align: center;
        line-height: 32px;
        border-radius: 100px;
        cursor: pointer;
      }
    }
  }

  .middle {
    text-align: center;
    margin: auto;
    left: 0;
    right: 0;
    position: absolute;
    margin-top: 255px;
    &_title {
      width: 546px;
      height: 90px;
    }
    &_search {
      width: 854px;
      height: 56px;
      background: rgba(255, 255, 255, 0.36);
      border-radius: 10px;
      margin: auto;
      margin-top: 21px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      position: relative;
      .keywordCont {
        opacity: 0;
        width: 794px;
        height: 192px;
        background: #ffffff;
        border-radius: 5px;
        position: absolute;
        left: 30px;
        top: 70px;
        z-index: 100;
        padding: 23px;
        display: none;
        box-sizing: border-box;
        &_title {
          font-size: 20px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 20px;
          text-align: left;
        }
        &_cont {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          // align-items: flex-start;
          align-content: flex-start;
          height: 110px;
          overflow-y: scroll;
          &_sub {
            cursor: pointer;
            width: fit-content;
            height: 40px;
            border: 1px solid #e2e4e5;
            opacity: 1;
            border-radius: 33px;
            box-sizing: border-box;
            text-align: center;
            font-size: 18px;
            color: #333333;
            line-height: 40px;
            margin: 0 6px 15px 5px;
            padding: 0px 20px;
            box-sizing: border-box;
          }
        }
      }
      &_close {
        cursor: pointer;
        position: absolute;
        left: 568px;
        top: 18px;
      }
      &_input {
        width: 580px;
        height: 50px;
        border: none;
        margin-left: 20px;
        background: none;
        outline: none;
        color: white;
      }
      input::-webkit-input-placeholder {
        /* placeholder颜色  */
        color: #ededed;
        /* placeholder字体大小  */
        font-size: 18px;
        /* placeholder位置  */
      }

      &_line {
        width: 1px;
        height: 32px;
        background: white;
      }
      .mapBtn {
        width: 118px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &_icon {
          width: 42px;
          height: 42px;
          margin-right: 5px;
        }
        &_txt {
        }
      }
      .searchBtn {
        width: 118px;
        height: 50px;
        background: $background-color-blue;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3px;
        cursor: pointer;

        &_icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        &_txt {
        }
      }
    }
  }

  .bottom {
    margin: auto;
    position: absolute;
    margin-top: 542px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(17, 51, 95, 0.51);
      border: 1px solid rgba(17, 51, 95, 0.51);
      width: 310px;
      height: 144px;
      box-sizing: border-box;
      border-radius: 5px;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      position: relative;
      cursor: pointer;
      .sub {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .bottom_img {
          width: 58px;
          height: 58px;
          margin-bottom: 13px;
        }
      }
    }

    > div:hover {
      border: 1px solid white;
      background: none;
    }
    &_info1:hover .bottom_img {
      background: url("../assets/images/index_btn1_active.png") no-repeat;
      background-size: 100% 100%;
    }
    &_info2:hover .bottom_img {
      background: url("../assets/images/index_btn2_active.png") no-repeat;
      background-size: 100% 100%;
    }
    &_info3:hover .bottom_img {
      background: url("../assets/images/index_btn3_active.png") no-repeat;
      background-size: 100% 100%;
    }
    &_info1 {
      .bottom_img {
        background: url("../assets/images/index_btn1.png") no-repeat;
        background-size: 100% 100%;
      }

      &.active {
        border: 1px solid white;
        background: none;
        .bottom_img {
          background: url("../assets/images/index_btn1_active.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    &_info2 {
      margin-left: 42px;
      margin-right: 42px;

      .bottom_img {
        background: url("../assets/images/index_btn2.png") no-repeat;
        background-size: 100% 100%;
      }

      &.active {
        border: 1px solid white;
        background: none;
        .bottom_img {
          background: url("../assets/images/index_btn2_active.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    &_info3 {
      .bottom_img {
        background: url("../assets/images/index_btn3.png") no-repeat;
        background-size: 100% 100%;
      }

      &.active {
        border: 1px solid white;
        background: none;
        .bottom_img {
          background: url("../assets/images/index_btn3_active.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}

// .topCont{
//        width: 100%;
//        height: 716px;
//        background: url('../assets/images/homeBg.jpg') no-repeat center ;
//        background-size: cover;
//   .lineCont{
//         width: 100%;
//         height: 64px;
//         background: rgba(0, 59, 148,.8);
//         // position: absolute;
//         top: 0;
//         left: 0;
//         text-align: left;
//         font-size: $font_medium;
//         color: white;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//     .lineCont_lineL{
//          display: flex;
//         justify-content: space-between;
//         align-items: center;

//         .lineCont_lineL_logo{
//             width: 52px;
//             height: 52px;
//             margin: 6px 0 6px 40px;

//         }
//         .lineCont_lineL_title{
//            margin-left: 10px;

//         }

//     }
//     .lineCont_lineR{
//        margin-right: 39px;
//        width: 64px;
//        height: 32px;
//        border: 1px solid rgba($color: #ffffff, $alpha: .8);
//        text-align: center;
//        line-height: 32px;
//        border-radius: 100px;
//     }
//     }
// }
</style>
