import axios from "axios";
import Vue from "vue";
import mode from "./modeType";

const qs = require("qs");

// axios.defaults.baseURL = ''
// axios.defaults.baseURL = 'https://dev.zixel.cn/'

// axios.defaults.baseURL = 'https://www.kabep.cn/'
axios.defaults.baseURL = "https://kabep.zixel.cn/";

axios.defaults.timeout = 5000;

let count = 0;

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    count++;
    //   Vue.showLoading()
    // console.log(config.url,'config.url')
    // console.log(mode.token,'token')

    if (config.method == "post") {
      config.data = qs.stringify(config.data, { arrayFormat: "brackets" });
    }
    if (mode.token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers["X-Token"] = mode.token; // 根据实际情况自行修改
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    //   Vue.hiddenLoading()
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    // console.log(typeof(response))
    let data = response.data;

    if (data.code != 200) {
      Vue.prototype.$message.error(data.message);
    }

    return response;
  },
  (error) => {
    // 对响应错误做点什么

    // this.$message.error(error.message);
    return Promise.reject(error);
  }
);

export default {
  get: function (path = "", data = {}) {
    return new Promise(function (resolve, reject) {
      axios
        .get(path, {
          params: data,
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },

  post: function (path = "", data = {}) {
    return new Promise(function (resolve, reject) {
      axios
        .post(path, data)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },

  all: function (list) {
    return new Promise(function (resolve, reject) {
      axios
        .all(list)
        .then(
          axios.spread(function (...result) {
            // 两个请求现在都执行完成
            resolve(result);
          })
        )
        .catch(function (err) {
          reject(err);
        });
    });
  },
};
