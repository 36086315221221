<!--  -->
<template>
  <div class="cardW">
      <div class="cardW_top">
        <div class="cardW_top_txt">{{title}}</div>
        <img class="cardW_top_tricon" src="@/assets/images/triangleIcon.png">  
        <img class="cardW_top_icon" @click="goNewPage1(newsType)" src="@/assets/images/cardIcon.png">
      </div>
      <ul class="cardList">
          <li class="cardList_item" v-for="(item,index) in infoList" :key="index" @click="goNewPage(item.id,newsType)"><span>{{item.title}}</span></li>
      </ul>

  </div>
</template>

<script>
import {mapActions,mapGetters} from 'vuex' 

export default {
name: '',
  data () {
    return {

    };
  },

  components: {},

  props: {
       infoList:{
            type: Array,
            default:()=>[],
            required: true
      },
       title:{
        type: String,
        default: '',
        required: true
      },
        newsType:{
        type: Number,
        default: 0,
        required: true
      },
  },

  computed: {},

  watch: {},

  methods: {
       ...mapActions([
          'setPageID',
      ]),
      goNewPage(_id,_type){
    //      console.log(_id)
    //       this.$router.push({path:'/newPage'})
    //       this.$router.push({
    //        path: `/newPage/${_type}/${_id}`  
    //       })
         this.$router.push({ name: 'NewPage', query: { id:_id,type:_type} });

        this.setPageID(4)

      },
       goNewPage1(_type){
        //   this.$router.push({
        //    path: `/newPage/${_type}`  
        //   })
        this.$router.push({ name: 'NewPage', query: { type:_type} });

       this.setPageID(4)

      }
  },

  created(){},//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){},//生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>
<style lang='scss' scoped>
//   @import "@/assets/css/variable";
  @import "@/assets/css/mixin";

.cardW{
    width: 466px;
    border-radius: 5px 5px 0px 0px;
    background: rgba(165, 165, 165,.06);
    &_top{
        width: 100%;
        height: 52px;
        border-radius: 5px 5px 0px 0px;
        background: $background-color-blue;;
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        position: relative;
       &_tricon{
           width: 16px;
           height: 16px;
           position: absolute;
           top: 52px;
           left: 54px;
        }
       &_txt{
         font-weight: bold;
         margin-left: 20px;
        //  @include clamp(1);
        text-align: left;

       } 
       &_icon{
           width: 30px;
           height: 30px;
           margin-right: 20px;
           cursor: pointer;
        
       }
    }

    .cardList{
        padding-bottom: 20px;
        margin-top: 6px;
        &_item{
          height: 64px;
          font-size: $font_large;
          color: #333333;
          display: flex;
          align-items: center;
          border-bottom: 1px dashed #C3C3C3;
          margin:0 20px 0 20px;
          cursor: pointer;
          span{

          @include no-wrap;
          @include clamp(1);
          }
          


        }
        &_item:hover{
              color: $background-color-blue;
          }
    }
}
</style>