import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageID: 1,//当前在哪个页面
    isLogin: false,//用户是否登录
    keyword: '',//输入框的文字
    topBoo: false,//滚回到顶部
  },
  mutations: {
     changeTopBoo(state,flag){
      state.topBoo=flag
    },
    changeKeyword(state,flag){
      state.keyword=flag
    },
    changePageID(state,flag){
      state.pageID=flag
    },
    changeIsLogin(state,flag){
      state.isLogin=flag
    },
  },
  actions: {
     setTopBoo({commit},song){
      commit('changeTopBoo',song)
    },
     setKeyword({commit},song){
      commit('changeKeyword',song)
    },
    setPageID({commit},song){
      commit('changePageID',song)
    },
    setIsLogin({commit},song){
      commit('changeIsLogin',song)
    },
  },
  getters: {
     getTopBoo(state) {
      return state.topBoo
    },
     getKeyword(state) {
      return state.keyword
    },
    getPageID(state) {
      return state.pageID
    },
    getIsLogin(state) {
      return state.isLogin
    }
  },
  modules: {
  }
})
