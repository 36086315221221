<!--  -->
<template>
   <div class="contactW">
         <div class="contactW_top">
            <div class="contactW_top_txtCn">联系方式</div>
            <div class="contactW_top_txtEn">contact information</div>
         </div>
         <div class="contactW_body">
            <!-- <div class="contactW_body_sub" v-for="(item,index) in contactList" :key="index"></div> -->
            <el-popover
            v-for="(item,index) in contactList" :key="index"
                 class="contactW_body_sub" 
                placement="top-start"
                width="160"
                trigger="hover">
                <p class="contactW_body_sub_title">{{item.name}}</p>
                <p class="contactW_body_sub_phone">电话：{{item.tel}}</p>
                <p class="contactW_body_sub_address"><span>地址：</span><span>{{item.address}}</span></p>
                <el-button slot="reference" @click="handleClick">{{item.name}}</el-button>
                </el-popover>
         </div>

      </div>
</template>

<script>
export default {
name: '',
  data () {
    return {
    };
  },

  components: {},

  props: {
      contactList:{
            type: Array,
            default:()=>[],
            required: true
      },
  },

  computed: {},

  watch: {},

  methods: {

     handleClick (e) {
            let target = e.target;
            if(target.nodeName == 'SPAN' || target.nodeName == 'I'){
                target = e.target.parentNode;
            }
            target.blur();
        }

  },

  created(){},//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){},//生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>
<style lang='scss'>
  @import "@/assets/css/variable";
.contactW_body{
.el-button {
  

    margin: 6px;
    font-size: $font_large;
    color: #333333;
    border: 1px solid #E2E4E5;
    border-radius: 33px;
    padding: 14px 40px;
}
.el-button:focus, .el-button:hover {
    color: #296ABC;
    border-color: #296ABC;
    background-color: rgba(0, 113, 255,.12);
}

}
.el-popover {
    position: absolute;
    background: #FFF;
    min-width: 434px;
    border: 1px solid #EBEEF5;
    padding: 30px;
    z-index: 2000;
    color: #606266;
    line-height: 1.4;
    text-align: justify;
    font-size: 14px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    word-break: break-all;
    box-sizing: border-box;
}
</style>
<style lang='scss' scoped>
  @import "@/assets/css/variable";

  .contactW{
      width: 100%;
      height: 454px;
      // height: 1600px;
      background: white;
      position: relative;
      overflow:hidden;
      &_top{
        margin-top: 69px;
        text-align: center;
        position: relative;
         &_txtCn{
            font-size: 32px;
            font-weight: bold;
            color: #296ABC;

         }
         &_txtEn{
           font-size: 32px;
          font-weight: bold;
          color: #333333;
          opacity: 0.1;
          position: relative;
          top: -23px;
         }
      }
       &_body{

        margin: auto;
        margin-top: 40px;
        margin-bottom: 90px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: $page-width;


         &_sub{
        //    margin: 10px;
        //    font-size: $font_large;
        //    color: #333333;
        //    border: 1px solid #E2E4E5;
        //    border-radius: 33px;
        //    padding: 14px 40px;
        //    cursor: pointer;
           &_title{
             font-size: 20px;
             color: #2869BB;
             
           }
           &_phone{
             font-size: 16px;
             margin: 8px 0;
           }
           &_address{
             font-size: 16px;
             display: flex;
             align-items: flex-start;
             justify-content: space-between;
             span:nth-child(2){
                 flex: 1;
             }
           }
         }
        //   &_sub:hover{
        //    color: #296ABC;
        //    border: 1px solid #296ABC;
        //    background: rgba(0, 113, 255,.12);
        //  }
      }

    }

</style>