<template>
  <div id="main">
    <Tabber></Tabber>
    <router-view />
    <Footer v-show="this.getPageID != 3"></Footer>

    <!-- <el-dialog title="外层 Dialog" :visible.sync="outerVisible">
    <div  class="dialog-footer">
      <el-button @click="outerVisible = false">取 消</el-button>
    </div>
  </el-dialog> -->

    <el-dialog :visible.sync="loginVisible" :show-close="false">
      <div class="dialogCont">
        <div class="rect">
          <i class="el-icon-error dialogCont_close" @click="closeLogin"></i>
          <img
            class="dialogCont_img"
            src="./assets/images/logoColour.png"
            alt=""
          />
          <div class="dialogCont_txt">
            <span></span>
            <div>账号/密码登录</div>
            <span></span>
          </div>
          <el-input
            class="dialogCont_input"
            type="text"
            autocomplete="off"
            v-model="inputAccount"
            placeholder="请输入登录账号"
          ></el-input>
          <el-input
            class="dialogCont_input"
            type="text"
            autocomplete="off"
            v-model="inputPassword"
            :show-password="true"
            placeholder="请输入登录密码"
          ></el-input>
          <div class="dialogCont_btn" @click="loginClick">登录</div>
          <img
            class="dialogCont_bgImg"
            src="./assets/images/bottomLogin.jpg"
            alt=""
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import Tabber from "@/views/Tabber.vue";
import Footer from "@/views/Footer.vue";
import { mapGetters, mapActions } from "vuex";
import { userLogin, userLogout } from "@/api/index";
import mode from "@/api/modeType";
import { setLocalStorage, getLocalStorage } from "./tools/tools";

export default {
  name: "Home",

  data() {
    return {
      loginVisible: false,
      inputAccount: "",
      inputPassword: "",
    };
  },
  components: {
    Tabber,
    Footer,
  },

  props: {},

  computed: {
    ...mapGetters(["getPageID", "getIsLogin"]),
  },

  watch: {},

  methods: {
    ...mapActions(["setIsLogin"]),
    closeLogin() {
      this.loginVisible = false;
    },
    openLogin() {
      if (this.getIsLogin) {
        userLogout()
          .then((data) => {
            // console.log(data,'userLogout')
            if (data.code == 200) {
              this.setIsLogin(false);
              this.$message({
                message: "退出成功",
                type: "success",
              });
            } else {
              this.$message.error(data.message);
            }

            // this.playlist = data.playlist
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err);
          });
      } else {
        this.inputAccount = "";
        this.inputPassword = "";
        this.loginVisible = true;
      }
    },

    loginClick() {
      // console.log(this.getIsLogin,'this.getIsLogin')

      if (this.inputAccount.length == 0) {
        this.$message({
          message: "请输入用户名!",
          type: "warning",
        });
        return;
      }
      if (this.inputPassword.length == 0) {
        this.$message({
          message: "请输入密码!",
          type: "warning",
        });
        return;
      }
      //admin  liuge1
      userLogin({
        useraccount: this.inputAccount,
        password: this.inputPassword,
      })
        .then((data) => {
          // console.log(data,'userLogin')
          if (data.code == 200) {
            mode.token = data.data.token;
            setLocalStorage("GDtoken", data.data.token);
            this.loginVisible = false;
            this.setIsLogin(true);
            this.$message({
              message: "恭喜你，登录成功!",
              type: "success",
            });
          } else {
            this.$message.error(data.message);
          }

          // this.playlist = data.playlist
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err);
        });
    },
  },

  created() {}, //生命周期 - 创建完成（可以访问当前this实例）
  mounted() {
    // console.log(getLocalStorage('GDtoken'),'GDtoken')
    if (getLocalStorage("GDtoken")) {
      mode.token = getLocalStorage("GDtoken");
    }

    //  userLogin({"useraccount":'zixel',"password": 'liuge1'})
    //         .then((data) => {
    //         // console.log(data,'userLogin')
    //         if(data.code==200){
    //              mode.token=data.data.token
    //              setLocalStorage('GDtoken',data.data.token)
    //              this.loginVisible=false
    //              this.setIsLogin(true)
    //             //  this.$message({
    //             //   message: '恭喜你，登录成功!',
    //             //   type: 'success'
    //             // });
    //         }else{
    //                this.$message.error(data.message);
    //            }

    //         // this.playlist = data.playlist
    //         })
    //         .catch((err) => {
    //           console.log(err)
    //           this.$message.error(err);
    //         })

    // console.log(mode.token,' Network.token')
  }, //生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss">
@import "@/assets/css/variable";

.loginCont {
  width: 718px;
  height: 567px;
  background: white;
}

#main {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: $page-width;
  overflow: hidden;
  .el-dialog {
    position: static;
    background: none;
    border: none;
    box-shadow: none;
    .dialogCont {
      width: 730px;
      height: 580px;
      background: rgba($color: #ffffff, $alpha: 0.3);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 20px;
      z-index: 100;
      .rect {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 20px;
        width: 718px;
        height: 567px;
        background: white;
        overflow: hidden;
      }
      &_close {
        color: #999999;
        font-size: 34px;
        position: absolute;
        cursor: pointer;
        top: 2%;
        right: 2%;
        &:hover {
          color: #c4c4c4;
        }
      }
      &_img {
        position: relative;
        width: 489px;
        margin: auto;
        margin-top: 68px;
        display: block;
      }
      &_txt {
        width: 100%;
        text-align: center;
        font-size: 22px;
        color: #999999;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 24px 0 37px 0;
        div {
          margin: 0 6px;
        }
        span {
          width: 166px;
          height: 1px;
          border-top: 2px dashed #5c5c5c;
          display: block;
        }
      }
      &_input {
        width: 488px;
        height: 62px;
        background: #ffffff;
        // border: 2px solid #DDDDDD;
        // border-radius: 10px;
        position: relative;
        display: block;
        margin: 20px auto;
        .el-input__inner {
          height: 100%;
          border-width: 2px;
          border-radius: 6px;
          font-size: 20px;
        }
      }
      &_btn {
        width: 488px;
        height: 62px;
        background: #296abc;
        border-radius: 10px;
        text-align: center;
        color: white;
        font-size: 22px;
        line-height: 62px;
        margin: auto;
        z-index: 100;
        position: relative;
        cursor: pointer;
        &:hover {
          background: #2e78d3;
        }
      }
      &_bgImg {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
  }
}
</style>
