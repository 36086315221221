

import Vue from 'vue'
// import ElementUI from 'element-ui';
import {Button,Icon,Select,Option,Pagination,Popover,Input,Dialog,Message,Image} from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'

import GaodeMap from 'vue-amap'

// import echarts from 'echarts'
import * as echarts from 'echarts';
// import './api/mock';

GaodeMap.initAMapApiLoader({
  // 高德的key
  key: '5bb582d2c5a0a192ab5ca8a39c9967c3',
  // 插件集合
  plugin: ['AMap.Autocomplete','AMap.MarkerClusterer','Geocoder', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 高德 sdk 版本，默认为 1.4.4
  v: '1.4.15'
});

import './assets/css/base.scss'
Vue.prototype.$echarts = echarts
Vue.prototype.$message = Message;

Vue.config.productionTip = false
Vue.use(Icon)
Vue.use(Select)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Popover)
Vue.use(Button)
Vue.use(Dialog)

Vue.use(Input)
Vue.use(Image);

Vue.use(GaodeMap);


// Vue.component(Icon.name, Icon);
// Vue.component(Select.name, Select);
// Vue.component(Option.name, Option);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
