<template>
  <div class="home">

    


      <div class="middleW">
         <div class="middleW_top">
            <div class="middleW_top_txtCn">推荐楼宇</div>
            <div class="middleW_top_txtEn">Recommended buildings</div>
         </div>
         <div class="middleW_body">
            <CardSub class="middleW_body_sub" v-for="(item,index) in recommendList" :type="0" :key="index" :infoList='item'></CardSub>
         </div>
      </div>

        <div class="topW">
         <div class="topW_left">
             <CardBule class="topW_left_policy" :title='"招商政策"' :newsType="3" :infoList='policyList'></CardBule> 
             <CardBule class="topW_left_info" :title='"楼宇资讯"' :newsType="2" :infoList='newsList'></CardBule> 
         </div>
         
         <div class="topW-right">
              <CardGrey class="topW_right_policy" :title='"招商信息"'  :newsType="1" :type="1" :infoList='infoList'></CardGrey> 
              <CardGrey class="topW_right_info" :title='"楼宇规划"'  :newsType="4" :type="2" :infoList='planList'></CardGrey> 
         </div>
      </div>

      <Contact :contactList="contactList"></Contact>
  </div>
</template>

<script>
// @ is an alias to /src
import CardBule from '@/components/Home/CardBule.vue'
import CardGrey from '@/components/Home/CardGrey.vue'
import CardSub from '@/components/Home/CardSub.vue'
import Contact from '@/components/Home/Contact.vue'
import {getNewsList,getMock,getNewsPagingList,getHousePagingList} from '@/api/index'
import mode from '@/api/modeType'
import {mapActions,mapGetters} from 'vuex' 


export default {
  name: 'Home',
  data () {
    return {
      policyList:[
        '昆明市官渡区促进跨境电子商质量发展扶持境电子商质量发境电子商质量发',
      '官渡区金融政策汇总',
      '中国（云南）自由贸易试验区昆明片区官渡区域…',
      '昆明市官渡区促进跨境电子商务高质量发展扶持…'
      ],
      newsList:['普洱茶特色小镇——官渡古镇片区及中国普洱茶…',
      '螺蛳湾——俊发螺蛳湾国际商贸城及俊发彩云城招商',
      '万科——万科商务写字楼项目',
      '首金商务中心——首创集团首金商务中心招商项目',
      '中海寰宇——中海寰宇中心高端写字楼招商项目'
      ],
     infoList:['普洱茶特色小镇——官渡古镇片区及中国普洱茶…',
      '螺蛳湾——俊发螺蛳湾国际商贸城及俊发彩云城招商项目',
      '万科——万科商务写字楼项目',
      '首金商务中心——首创集团首金商务中心招商项目',
      '中海寰宇——中海寰宇中心高端写字楼招商项目',
      '螺蛳湾——俊发螺蛳湾国际商贸城及俊发彩云城湾国际商贸城及俊发彩云城招商项湾国际商贸城及俊发彩云城招商项招商项目',
      '万科——万科商务写字楼项目',
      '首金商务中心——首创集团首金商务中心招商项目',
      '中海寰宇——中海寰宇中心高端写字楼招商项目',
      '中海寰宇——中海寰宇中心高端写字楼招商项目',
      '中海寰宇——中海寰宇中心高端写字楼招商项目'
      ],
      planList:['《昆明中心城区官渡区控制性详细规划梳理》公布'
      
      ],
      recommendList:[
        {name:'自贸数字港'},
        {name:'自贸数字港'},
        {name:'自贸数字港'},
        {name:'自贸数字港'},
        {name:'自贸数字港'},
        {name:'自贸数字港'}
      ],
      contactList:[
        {name:'六甲街道办事处',address:'昆明市官渡区福保路东50米',tel:'0871-37323472'},
         {name:'矣六街道办事处',address:'云南省昆明市官渡区彩云北路5151号螺蛳湾国际商贸城2期2号6楼',tel:'0871-64280550'},
         {name:'官渡街道办事处',address:'昆明市官渡区云秀路2016号',tel:'0871-67372681'},
         {name:'小板桥街道办事处',address:'云南省昆明市官渡区小板桥镇中街10号',tel:'0871-67363561'},
         {name:'金马街道办事处',address:'昆明市官渡区金马寺大村6号',tel:'0871-63857163'},
         {name:'吴井街道办事处',address:'云南省昆明市官渡区岔街63',tel:'0871-63392027'},
         {name:'关上街道办事处',address:'云南省昆明市官渡区关平路25号',tel:'0871-67186871'},
         {name:'太和街道办事处',address:'昆明市官渡区春城路与和平路交叉路口往西南约100米(和平新村)',tel:'0871-63130116'}
       
      ]
    };
  },
  components: {
    CardBule,
    CardGrey,
    CardSub,
    Contact
  },

  props: {},

  computed: {},

  watch: {},

  methods: {
        ...mapActions([
          'setPageID',
      ]),
  },

  created(){},//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){
    // mode.token='33333dddd'
    
    this.setPageID(1)
     getNewsPagingList({ contentType: '招商政策' ,pageNo:1, pageSize:4})
        .then((data) => {
          if(data.code==200){
            // console.log(data,'getPlayList招商政策')
            this.policyList=data.data.records

            }else{
             this.$message.error(data.message);
          }
          
          // this.playlist = data.playlist
        })
        .catch((err) => {
          console.log(err)
          this.$message.error(err);
        })

      getNewsPagingList({ contentType: '楼宇资讯' ,pageNo:1, pageSize:5})
        .then((data) => {
          if(data.code==200){
            // console.log(data,'getPlayList楼宇资讯')
            this.newsList=data.data.records

            }else{
             this.$message.error(data.message);
          }
          
          // this.playlist = data.playlist
        })
        .catch((err) => {
          console.log(err)
          this.$message.error(err);
        })   


        getNewsPagingList({ contentType: '招商信息' ,pageNo:1, pageSize:11})
        .then((data) => {
          if(data.code==200){
            // console.log(data,'getPlayList招商信息')
            this.infoList=data.data.records

            }else{
             this.$message.error(data.message);
          }
          
          // this.playlist = data.playlist
        })
        .catch((err) => {
          console.log(err)
          this.$message.error(err);
        })  

       getNewsPagingList({ contentType: '楼宇规划' ,pageNo:1, pageSize:1})
        .then((data) => {
          if(data.code==200){
                // console.log(data,'getPlayList楼宇规划')
                this.planList=data.data.records
            }else{
             this.$message.error(data.message);
          }
          
          // this.playlist = data.playlist
        })
        .catch((err) => {
          console.log(err)
          this.$message.error(err);
        })  


      getHousePagingList({pageNo:1, pageSize:8,setupYearMin: 0,
      setupYearMax:5,orderCol: 2})
        .then((data) => {
          if(data.code==200){
            //  console.log(data,'recommendList')
             this.recommendList=data.data.records
            //  this.recommendList[0].decorateStandard= "普通装修,毛坯,精装修,普通装修,普通装修,普通装修"

            }else{
             this.$message.error(data.message);
          }
         
          // this.playlist = data.playlist
        })
        .catch((err) => {
          console.log(err)
          this.$message.error(err);
        })  
        
        

    // getMock()
    //     .then((data) => {
    //       console.log(data,'getMockgetMockgetMock')
    //       // this.playlist = data.playlist
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
        


  },//生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang='scss' scoped>
  @import "@/assets/css/variable";

.home{
    width: 100%;
    // height: 3052px;
    position: relative;
    background: $background-color;
    .topW{
      padding-top: 64px;
      position: relative;
      // width: fit-content;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      left: 0;
      right: 0;
      background: white;
      padding-bottom: 61px;
      &_left{
        position: relative;
        margin-right: 10px;
        &_info{
          margin-top: 20px;
          height: 403px;
        }
        &_policy{
          height: 338px;
        }
      }

      &_right{
        position: relative;
        margin-left: 10px;
        &_info{
          margin-top: 20px;
          // height: 238px;
        }
         &_policy{
          height: 590px;
        }
        
      }


    }

    .middleW{
      width: 100%;
      // height: 1600px;
      background: $background-color;
      // margin-top: 61px;
      position: relative;
      overflow:hidden;
      &_top{
        margin-top: 69px;
        text-align: center;
        position: relative;
         &_txtCn{
            font-size: 32px;
            font-weight: bold;
            color: #296ABC;

         }
         &_txtEn{
           font-size: 32px;
          font-weight: bold;
          color: #333333;
          opacity: 0.1;
          position: relative;
          top: -23px;
         }
      }

      &_body{
        margin: auto;
        margin-top: 33px;
        margin-bottom: 90px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        
        width: $page-width;

         &_sub{
          //  margin: 5px;
          margin-bottom: 20px;
         }
      }

    }


}
</style>