export default{
    token: 'asdasdasd',
    topBoo: false,
    oderIndex: 1,
    newCurrentPage: 1,
    
    projectCategory: 0,
    townId: 0,
    decorateStandard: 0,
    operatingMethod: 0,
    identifiedLevel: 0,
    setupYear: 0,
    vacancyRate: 0,
    rentArea: 0,
    rentNum: 0,
    rentMax: '',
    rentMin:'',
    jisBoo: false,
    
    roomObj: '',
    newTotal:0,
    
    apiObj: '',

    defStr:'--'
    
    
} 

    