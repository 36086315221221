import Network from '@/api/network'


export const userLogin = (data) => Network.post('app/building/user/login', data)//用户登陆
export const userLogout = (data) => Network.post('app/building/user/logout', data)//用户登出
export const isLogin = () => Network.get('app/building/user/isLogin')//用户是否登出


export const getNewsList = (data) => Network.get('app/building/news/list', data)//获取新闻列表
export const getNewsPagingList = (data) => Network.get('app/building/news/page', data)//新闻分页查询
export const getNewsDetailed = (data) => Network.get('app/building/news/ne', data)//新闻详情


export const getHouseHistory = (data) => Network.get('app/building/history', data)//历史数据
export const getHouseList= (data) => Network.get('app/building/list', data)//查询所有楼宇
export const getHouseDetailed = (data) => Network.get('app/building/ne', data)//查询楼宇详情
export const getHouseNearBy = (data) => Network.get('app/building/nearBy', data)//楼宇周边配套
export const getHousePagingList = (data) => Network.get('app/building/page', data)//分页查询楼宇


export const getDictList = (data) => Network.get('/app/dict/lists', data)//批量查询字典列表

export const getTownsList = () => Network.get('/app/dict/towns')//街道列表

// 楼宇类型：resCreProject.projectCategory
// 所属街道: 通用接口-街道查询
// 楼宇特色-认定等级：resCreProject.identifiedLevel
// 楼宇特色-是否自贸区楼宇：是、否
// 装修标准：resCreFloorOper.decorateStandard
// 运营模式：resCreBuilding.operatingMethod

export const getMock = (data) => Network.get('/ethan', data)//获取新闻列表
