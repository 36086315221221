<!--  -->
<template>
  <div class="cardW" @click="detailPage(infoList)">
       <div class="cardLeft">
          <!-- <img class="cardLeft_img" src="@/assets/images/house.jpg" alt=""> -->
         <el-image :src="infoList.imageUrl" fit="cover" lazy>
            <!-- <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
            </div> -->
         </el-image>


       </div>
       <div class="cardRight">
           <div class="cardRight_subN">
                <div class="cardRight_subN_title">{{infoList.projectName}}</div>
                <!-- <div class="cardRight_subN_right" @click="detailPage1(infoList.id)"><span>{{type==3?'进入详情':infoList.buildingName}}</span><i class="el-icon-arrow-right"></i></div> -->
                 <div class="cardRight_subN_right" @click="detailPage1(infoList.id)"><span>进入详情</span><i class="el-icon-arrow-right"></i></div>

           </div>
           <div class="cardRight_title">{{infoList.buildingName}}</div>
            <div class="cardRight_subT">
                <div v-for="(item,index) in (infoList.decorateStandard||'').split(',')" :key="index" :class="'cardRight_subT_tag'+(index%3+1)"  v-show="item">{{item}}</div>
                <div  class="cardRight_subT_tag"> 1</div> 
                
                <!-- <div class="cardRight_subT_tag1">亿元楼宇</div>
                <div class="cardRight_subT_tag2">千万元楼宇</div>
                <div class="cardRight_subT_tag3">亿元楼宇</div> -->
           </div>
           <!-- <div v-else class="cardRight_subT1"></div> -->
            <div class="cardRight_sub1">
                <div class="cardRight_sub1_tag1">租金范围:</div>
                <div class="cardRight_sub1_tag2"><span class="redAcite">{{infoList.rentL}}-{{infoList.rentH}}</span>元/月/平</div>
           </div>
             <div class="cardRight_sub1">
                <div class="cardRight_sub1_tag1">单层最大连续可租面积:</div>
                <div class="cardRight_sub1_tag2"><span class="redAcite">{{infoList.rentAreaH==null?0:infoList.rentAreaH}}</span>平</div>
           </div>
            <div class="cardRight_sub1">
                <div class="cardRight_sub1_tag1">售价范围:</div>
                <div class="cardRight_sub1_tag2"><span class="redAcite">{{infoList.saleL}}-{{infoList.saleH}}</span>元/平</div>
           </div>
            <div class="cardRight_sub1">
                <div class="cardRight_sub1_tag1">单层最大连续可售面积:</div>
                <div class="cardRight_sub1_tag2"><span class="redAcite">{{infoList.saleAreaH==null?0:infoList.saleAreaH}}</span>平</div>
           </div>
           <div class="cardRight_sub1">
                <div class="cardRight_sub1_tag1">楼层数:</div>
                <div class="cardRight_sub1_tag2"><span class="redAcite">{{infoList.floorCount}}</span>层</div>
           </div>
           <div class="cardRight_sub1">
                <div class="cardRight_sub1_tag1">运营模式:</div>
                <div class="cardRight_sub1_tag2">{{infoList.operatingMethod}}</div>
           </div>
       </div>
  </div>
</template>

<script>
import {mapActions,mapGetters} from 'vuex' 

export default {
name: '',
  data () {
    return {
    };
  },

  components: {},

  props: {
    type:{
            type: Number,
            default:()=>0,
            required: true
      },
    infoList:{
        decorateStandard:[],
    }
  },

  computed: {},

  watch: {},

  methods: {
        ...mapActions([
          'setPageID',
      ]),
      detailPage(_obj){
        
        //  this.$router.push({path:'/newPage'})
        //   this.$router.push({
        //    path: `/roomDetails/${_id}`  
        //   })
        if(this.type==3){
            //  console.log(_obj,'_obj_obj_obj111')
            this.$emit('clickRect',_obj)

        }else{
            //  console.log(_obj,'_obj_obj_obj2222')
            this.$router.push({ name: 'RoomDetails', query: { id:_obj.id} });
            this.setPageID(5)
        }
        

      },
       detailPage1(_id){
        //  console.log(_id)
        if(this.type==3){
            this.$router.push({ name: 'RoomDetails', query: { id:_id} });
            this.setPageID(5)
        }
        

      }
  },

  created(){},//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){},//生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>
<style lang='scss' scoped>
@import "@/assets/css/mixin";

.cardW{
    width: 590px;
    height: 260px;
    background: #FEFEFE;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    .el-icon-arrow-right{
        font-size: 20px;
        color: rgb(131, 131, 131);
        font-weight: bold;
        margin-left: 7px;
       
    }
    .cardLeft{
        width: 162px;
        height: 230px;
        background: #FEFEFE;
        border-radius: 5px;
        overflow: hidden;
        margin-left: 8px;
        .el-image{
            width: 100%;
            height: 100%;
        }
        .cardLeft_img{
            width: 100%;
            height: 100%;
           object-fit: cover;  

        }

    }
    ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
    }
    .cardRight{
        margin-left: 20px;
        height: fit-content;
        flex: 1;
        margin-right: 20px;
        width: 100%;
        &_title{
            color: rgb(37, 37, 37);
            margin: 3px 0;
            text-align: left;
            @include clamp(1);
            @include no-wrap();
            width: 360px;

         }
        &_subN{
             display: flex;
             align-items: center;
             justify-content: space-between;
            &_title{
              color: #333333;
              font-size: 22px;
              font-weight: bold;
               @include clamp(1);
               @include no-wrap();
               width: 260px;
               text-align: left;
            }
            &_right{
               
                display: flex;
                align-items: center;
                justify-content: flex-end;
                 width: 100px;
                span{
                  @include clamp(1);
                  @include no-wrap();
                  
                    //  line-height: 0;
                    font-size: 18px;
                    color: #333333;
                    text-align: right;
                }
            }
        }
        &_subT1{
             display: flex;
             align-items: center;
             justify-content: flex-start;
             margin-top: 3px;
             width: 100%;
             overflow-x:scroll;
             color: white;
             }

        &_subT{
             display: flex;
             align-items: center;
             justify-content: flex-start;
             margin-top: 3px;
             width: 100%;
             overflow-x:scroll;
             color: white;
             &_tag1{
                 background: #D40D0D;
                 border-radius: 13px;
                 font-size: 14px;
                 padding: 3px 12px;
                 margin-right: 8px;
                 flex: none;
             }
              &_tag2{
                 background: #79AAD5;
                 border-radius: 13px;
                 font-size: 14px;
                 padding: 3px 12px;
                 margin-right: 8px;
                 flex: none;
             }
              &_tag3{
                 background: #339B00;
                 border-radius: 13px;
                 font-size: 14px;
                 padding: 3px 12px;
                 margin-right: 8px;
                 flex: none;
             }
        }

         &_sub1{
             display: flex;
             align-items: center;
             justify-content: space-between;
             margin-top: 3px;
             color: #999999;
              font-size: 16px;
             &_tag1{
              
             }
              &_tag2{
                 .redAcite{
                     color: #B50000;
                     font-weight: bold;
                 }
             }
            
        }



    }
}
</style>