<!--  -->
<template>
  <div class="cardW">
      <div class="cardW_top">
         <div>
            <img class="cardW_top_tricon" src="@/assets/images/triangleIcon.png">  
            <div class="cardW_top_txt">{{title}}</div>
            <img class="cardW_top_icon" @click="goNewPage1(newsType)" src="@/assets/images/cardIconG.png">
         </div>
      </div>
      <ul class="cardList">
           <li class="cardList_item" v-for="(item,index) in infoList" :key="index" @click="goNewPage(item.id,newsType)">
              <div class="cardList_item_txt"><span v-show="type==1"></span>{{item.title}}</div>
              <div class="cardList_item_date" v-show="type==1">{{item.publishDate}}</div>
           </li>
      </ul>

  </div>
</template>

<script>
import {mapActions,mapGetters} from 'vuex' 

export default {
name: '',
  data () {
    return {

    };
  },

  components: {},

  props: {
       infoList:{
            type: Array,
            default:()=>[],
            required: true
      },
       title:{
        type: String,
        default: '',
        required: true
      },
       type:{
        type: Number,
        default: 0,
        required: true
      },
        newsType:{
        type: Number,
        default: 0,
        required: true
      },
  },

  computed: {},

  watch: {},

  methods: {
        ...mapActions([
          'setPageID',
      ]),
       goNewPage(_id,_type){
         console.log(_id)
        //  this.$router.push({path:'/newPage'})
        //   this.$router.push({
        //    path: `/newPage/${_id}/${_type}`  
        //   })
         this.$router.push({ name: 'NewPage', query: { id:_id,type:_type} });

       this.setPageID(4)

      },
       goNewPage1(_type){
        //   this.$router.push({
        //    path: `/newPage/${_type}`  
        //   })
        this.$router.push({ name: 'NewPage', query: { type:_type} });

       this.setPageID(4)

      }

  },

  created(){},//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){},//生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>
<style lang='scss' scoped>
//   @import "@/assets/css/variable";
  @import "@/assets/css/mixin";

.cardW{
    width: 714px;
    background: rgba(165, 165, 165,.06);
    border-radius: 5px 5px 0px 0px;
    &_top{
        width: 100%;
        height: 52px;
        font-size: 20px;
        position: relative;
        color:  $background-color-blue;;        
        >div{
        height: 100%;   
        border-bottom: 1px solid #E2E2E2;
        margin: 0 20px 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        }
       &_tricon{
           width: 16px;
           height: 16px;
           position: absolute;
           top: 52px;
           left: 54px;
           
        }
       &_txt{
         font-weight: bold;
         @include clamp(1)

       } 
       &_icon{
           width: 30px;
           height: 30px;
           cursor: pointer;
        
       }
    }

    .cardList{
        padding-bottom: 43px;
        margin-top: 11px;
        &_item{
          height: 44px;
          font-size: $font_large;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin:0 20px 0 20px;
          cursor: pointer;
        //   @include no-wrap();
          
          &_txt{
              
              span{
                   width: 10px;
                    height: 10px;
                    border: 2px solid #22569A;
                    border-radius: 100%;
                    display: inline-block;
                    margin-right: 6px;
                    box-sizing:border-box
              }
              width: 80%;
              @include no-wrap;
              @include clamp(1);
          }
          &_txt:hover{
              color: $background-color-blue;
              }
          &_date{
             color: #999999;
          }


        }
    }
}
</style>