<!--  -->
<template>
  <div class="footerW">
    <div class="line"></div>
    <div class="footerW_txt1">相关链接</div>
    <div class="footerW_txt2">
      <a href="https://www.kabep.cn:8120/#/cre/project" target="_blank"
        >楼宇信息维护中心</a
      ><span>|</span
      ><a href="http://www.kmgd.gov.cn/" target="_blank"
        >昆明市官渡区人民政府官网</a
      ><span>|</span
      ><a
        href="https://720yun.com/t/2dvkOe7758l?scene_id=54718391"
        target="_blank"
        >官渡VR全景看楼</a
      >
    </div>
    <div class="footerW_txt3">
      主办单位：昆明市官渡区商务和投资促进局　地址：昆明市官渡区云秀路2898号　电话：0871-67173522<br />备案号：沪ICP备2020033212号　承办单位：上海子虔科技有限公司
    </div>
    <div class="footerW_img">
      <img
        class="footerW_img_icon1"
        src="@/assets/images/footerIcon_1.png"
        alt=""
      />
      <img
        class="footerW_img_icon2"
        src="@/assets/images/footerIcon_2.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },

  components: {},

  props: {},

  computed: {},

  watch: {},

  methods: {},

  created() {}, //生命周期 - 创建完成（可以访问当前this实例）
  mounted() {}, //生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/variable";

.footerW {
  position: relative;
  // margin-top: 29px;
  text-align: center;
  background: $background-color;
  padding-bottom: 80px;
  color: #999999;
  .line {
    // background: #EB0000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #2563af,
      #1f7dd3,
      #2563af
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #2563af,
      #1f7dd3,
      #2563af
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    height: 14px;
  }
  &_txt1 {
    font-size: $font_large;
    color: #061421;
    font-weight: bold;
    margin: 20px 0;
  }
  &_txt2 {
    font-size: $font_medium;
    margin: 0 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // cursor: pointer;
    a {
      color: #999999;
    }
    a:hover {
      color: #bdbdbd;
    }
    span {
      margin: 0 15px;
      font-size: 26px;
    }
  }
  &_txt3 {
    font-size: 14px;
  }
  &_img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    &_icon1 {
      width: 94px;
      height: 68px;
    }
    &_icon2 {
      width: 68px;
      height: 68px;
    }
  }
}
</style>
